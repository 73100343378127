//
// Page loader(used by the previous layout system)
//

// CSS3 Transitions only after page load(.page-loading class added to body tag and remove with JS on page load)
[data-kt-app-page-loading='on'] *,
.page-loading * {
  transition: none !important;
}

// Base
.page-loader {
  background-color: var(--#{$prefix}body-bg);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: none;

  [data-kt-app-page-loading='on'] &,
  .page-loading & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
