.catalog-products {
  max-height: 420px;
  overflow-y: scroll;

  .catalog-product {
    height: 85px;

    .symbol {
      background: white;
    }
  }
}

.product {

  .title {
    color: $white;
  }

  .subtitle {
    color: $gray-600-dark;
  }

  .nav-link.active, .nav-link.active:hover {
    color: $primary !important;
    border-bottom: 2px solid var(--bs-text-primary) !important;
  }

  .nav-link:hover {
    border-bottom: none !important;
  }
}

.dropzone {
  background: transparent !important;
  border-color: $gray-800 !important;

  .square-image {
    display: flex;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    max-height: 175px !important;
    max-width: 175px !important;
    padding: 2em !important;
    aspect-ratio: 1/1 !important;
  }
}

.rti--container {
  background-color: var(--bs-gray-100) !important;
  border-color: var(--bs-gray-100) !important;
  min-height: 90px;
  align-items: start !important;
  padding: 0.9em !important;

  &:focus-within {
    background-color: var(--bs-gray-200) !important;
    border-color: var(--bs-gray-200) !important;
    box-shadow: none !important;
  }

  .rti--tag {
    background-color: $primary !important;
    padding: inherit;
    min-width: 52px;
    height: 25px;
    justify-content: space-between;
    font-weight: bold;

    button {
      font-weight: bolder;
      color: $gray-800-dark;
      padding: 0 0 0 0.5em;
      font-size: 0.9em;

      &:hover {
        color: $gray-700-dark;
      }
    }
  }

  input {
    min-height: 25px !important;
    background-color: transparent;
    color: white;
  }
}

.product-img {
  display: flex;
  justify-content: center;

  height: 200px;
  width: 200px;
  background: white;
  border-radius: 0.75em;
  padding: 2em;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}