//
// Toolbar
//

// General
.toolbar {
  display: flex;
  align-items: center;
  width: 100%;
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .toolbar {
    flex-direction: column;
    background: var(--#{$prefix}header-bg-color);
  }
}
