ul.timeline {
  list-style-type: none;
  position: relative;
  padding-top: 15px;
  padding-left: 0px;
}
ul.timeline:before {
  content: ' ';
  background: transparent;
  display: inline-block;
  position: absolute;
  width: 2px;
  height: 100%;
  z-index: 5;
  border-left: 2px dashed var(--bs-card-border-color);
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  margin-top: 5px;
  content: ' ';
  background: var(--bs-primary);
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: none;  /* Remove the border */
  left: -4px;
  width: 10px;   /* Make the dot smaller */
  height: 10px;  /* Make the dot smaller */
  z-index: 5;
}


.square-image {
  padding: 7em;
  width: 100%;
  aspect-ratio: 4/3.5; /* This creates a square */
  overflow: hidden;
}

.square-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}