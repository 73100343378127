.react-datepicker {
  background-color: var(--bs-body-bg) !important;
  box-shadow: var(--bs-dropdown-box-shadow) !important;
  border: 0 !important;
  padding-top: 1em;
}

.react-datepicker__header {
  background-color: var(--bs-body-bg) !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: var(--bs-body-color) !important;
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  color: var(--bs-body-color) !important;

}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: var(--bs-body-color) !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: var(--bs-primary-bg-subtle);
}

.react-datepicker__day {
  margin-left: 0;
  margin-right: 0;
  border-radius: 0 !important;
}


.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
  background-color: var(--bs-component-hover-bg) !important;
  color: var(--bs-component-hover-color) !important;
}

.react-datepicker__day--selected, .react-datepicker__day--selecting-range-start {
  background-color: var(--bs-component-active-bg) !important;
  color: var(--bs-component-active-color) !important;
  border-top-left-radius: 2em !important;
  border-bottom-left-radius: 2em !important;
}

.react-datepicker__day--range-end, .react-datepicker__day--selecting-range-end {
  background-color: var(--bs-component-active-bg) !important;
  color: var(--bs-component-active-color) !important;
  border-top-right-radius: 2em !important;
  border-bottom-right-radius: 2em !important;
}

.react-datepicker__navigation {
  margin-top: 2em !important;
}