.smaller {
  font-size: 0.8em !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.text-grey {
  color: $gray-800-dark !important;
}

.text-danger-hover:hover {
  color: $danger-active !important;
}