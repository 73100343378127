.size-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-left: -1.2em;
  margin-top: -1.2em;

  .size {
    border: 1px solid $gray-200-dark;
    border-radius: 0.3125em;

    display: flex;
    flex-direction: column;

    height: 45px;
    width: 90px;

    justify-content: center;
    align-items: center;

    font-weight: bold;

    margin-left: 1.2em;
    margin-top: 1.2em;

    cursor: pointer;

    >.price {
      font-size: 0.8em;
      font-weight: 400;
      color: $gray-600-dark;
    }

    &.selected {
      background-color: $gray-400-dark;

      &:hover {
        background-color: $gray-300-dark !important;
      }
    }

    &:hover {
      background-color: $gray-200-dark;
    }
  }
}

.size-input:focus-within {
  & > .input-group-text {
    background: var(--bs-gray-200) !important;
  }
}

.tableContainer {
  width: 100%; /* or whatever width you want */
  height: 520px; /* adjust as needed */
  overflow-y: auto;
  position: relative;
  margin-top: 1.25rem;

  .item-headers {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--bs-card-bg)
  }

  .row {
    .item {
      height: 50px;
      max-height: 50px;
    }

    padding-bottom: 1em;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
  }
}